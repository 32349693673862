import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import Container from 'react-bootstrap/Container';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { parseBanner } from 'components/Banner/parsers';

import './CovidPage.scss';

interface ICovidPageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: CovidPageTypes.ICovidPageData;
}

const CovidPage: FC<ICovidPageProps> = ({
  pageContext: { breadcrumbs },
  data: {
    covidPage: {
      covidPageBanner,
      covidTextField,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      lang,
    },
    header,
    footer,
    siteSettings,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => (
  <Layout
    seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage }}
    header={header}
    footer={footer}
    siteSettings={siteSettings}
    newsletterPopupDefault={newsletterPopupDefault}
    newsletterPopupDiscount={newsletterPopupDiscount}
    newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
    disclaimerPopup={disclaimerPopup}
    searchPath={searchPath}
    brandSettings={brandSettings}
    legalPopup={legalPopup}
    langSettings={{
      currentLang: lang,
      langs,
    }}
  >
    <div className="covid-page">
      <Banner {...parseBanner(covidPageBanner?.[0]?.properties)} breadcrumbs={breadcrumbs} />

      <div className="covid-page__content">
        <Container fluid>
          <DangerouslySetInnerHtml html={covidTextField} />
        </Container>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query($lang: String!, $link: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    ...FragmentCommonCompositions
    pageLangs: allCovidPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    covidPage(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      covidPageBanner {
        properties {
          ...FragmentBanner
        }
      }
      covidTextField
    }
  }
`;

export default CovidPage;
